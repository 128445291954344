/* eslint-disable @next/next/no-img-element */
import React, { type FC, Fragment, useContext, useRef } from 'react';
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { useIsLoggedIn } from 'bb/account/hooks/useVerifyLoggedIn';
import { AppContext } from 'bb/app/AppContext';
import { Link } from 'bb/app/nav/Link';
import css from 'bb/app/nav/Navbar/navbar.module.scss';
import { useRouter } from 'bb/app/router';
import { type RouterQuery } from 'bb/app/router/types';
import { useMarket } from 'bb/app/useMarket';
import { usePaymentMethods } from 'bb/app/usePaymentMethods';
import { AppDownloadBadges } from 'bb/common/AppDownloadBadges';
import { MaxWidthContainer } from 'bb/common/Containers';
import { ExternalLink } from 'bb/common/ExternalLink';
import { KindlyChatWidget } from 'bb/common/KindlyChatWidget';
import { LoggingErrorBoundary } from 'bb/common/LoggingErrorBoundary';
import Logo from 'bb/common/Logo';
import { NumberOfBooks } from 'bb/common/NumberOfBooks';
import { PagePaddingBox } from 'bb/common/PagePaddingBox';
import { useFeature } from 'bb/config/features';
import { getMarketConfig } from 'bb/config/marketConfig';
import { Text, useTranslation } from 'bb/i18n';
import { CCImages } from 'bb/payment';
import commonCss from 'bb/style/common.module.scss';
import { Box, Flex, List, ListItem, Row, Stack } from 'bb/ui';
import cssFooter from './footer.module.scss';
import { FooterListItem } from './FooterListItem';
import { Impressum } from './Impressum';

const MarketPickerButton = dynamic(
    () =>
        import('../MarketPicker/MarketPickerButton').then(
            (m) => m.MarketPickerButton
        ),
    { ssr: true }
);

const CookieSettingsButton = dynamic(
    () => import('./CookieSettingsButton').then((m) => m.CookieSettingsButton),
    { ssr: false }
);

const paymentMethodsConfig = (additionalCreditCards: string[] = []) => ({
    scheme: (
        <CCImages
            cards={['mastercard', 'visa'].concat(additionalCreditCards)}
            justifyContent="flexStart"
        />
    ),
    paypal: (
        <img
            alt="paypal logo"
            className={cssFooter.paypal}
            src="/images/cc/paypal.png"
            loading="lazy"
        />
    ),
    klarna_paynow: (
        <img
            alt="klarna logo"
            className={cssFooter.klarna}
            src="/images/cc/klarna.png"
            loading="lazy"
        />
    ),
    applepay: (
        <img
            alt="Apple pay logo"
            className={cssFooter.apple}
            src="/images/cc/ApplePayIcon.png"
            loading="lazy"
        />
    ),
    googlepay: (
        <img
            alt="Google pay logo"
            className={cssFooter.google}
            src="/images/cc/google_pay.png"
            loading="lazy"
        />
    ),
    ideal: (
        <img
            alt="Ideal logo"
            className={cssFooter.ideal}
            src="/images/cc/ideal.png"
            loading="lazy"
        />
    ),

    giftCard: null
});

// eslint-disable-next-line complexity
export const Footer: FC = () => {
    const { t } = useTranslation([
        'nav',
        'common',
        'footer',
        'accountActivated',
        'countries'
    ]);
    const footerRef = useRef<HTMLAnchorElement>(null);
    const { routes, router } = useRouter();
    const { market } = useContext(AppContext);
    const enabledCancelSubscription = useFeature('cancel-subscription');
    const { paymentMethods } = usePaymentMethods();
    const { marketSettings } = useMarket();
    const isLoggedIn = useIsLoggedIn();
    const {
        giftCard: showGiftCard,
        footer: footerItems,
        additionalCreditCards,
        showPartners
    } = getMarketConfig(market) || {};

    const additionalFooterItems = footerItems || [];
    const weblinks = marketSettings?.weblinks;

    const showImpressum = additionalFooterItems?.includes('impressum');
    const paymentConfig = paymentMethodsConfig(additionalCreditCards);

    if (paymentMethods) {
        paymentMethods.sort((a, b) => {
            if (a.type === 'scheme' && b.type !== 'scheme') {
                return -1;
            }
            if (a.type !== 'scheme' && b.type === 'scheme') {
                return 1;
            }
            return 0;
        });
    }

    return (
        <footer className={cssFooter.base} ref={footerRef}>
            <nav className={css.black}>
                <PagePaddingBox pagePadding padding="large">
                    <LoggingErrorBoundary errorName="KindlyError">
                        <KindlyChatWidget
                            feature="kindly-chatbot-account"
                            intersectionRef={footerRef}
                        />
                    </LoggingErrorBoundary>
                    <MaxWidthContainer maxWidth={992}>
                        <Stack
                            justifyContent="spaceBetween"
                            alignItems="stretch"
                            className={cssFooter.row}
                        >
                            <Stack
                                alignItems="flexStart"
                                className={cssFooter.bookbeat}
                            >
                                <Link
                                    route={
                                        isLoggedIn
                                            ? routes.welcome
                                            : routes.start
                                    }
                                    title={t('common:startPageLinkLabel')}
                                >
                                    <span>
                                        <Logo
                                            className={classnames(
                                                css.logo,
                                                css.whiteLogo
                                            )}
                                            variant="white"
                                        />
                                    </span>
                                </Link>

                                <Text t="footer:pitch" color="primary-white">
                                    <NumberOfBooks />
                                    <Link route={routes.start} />
                                </Text>
                            </Stack>

                            <Row
                                flexWrap="wrap"
                                className={cssFooter.navigationRow}
                            >
                                <List className={cssFooter.navList}>
                                    <ListItem>
                                        <Text
                                            t="footer:aboutHeader"
                                            color="primary-white"
                                        />
                                    </ListItem>
                                    <FooterListItem>
                                        <Link route={routes.about}>
                                            <Text as="span" t="footer:about" />
                                        </Link>
                                    </FooterListItem>
                                    {weblinks?.presslink && (
                                        <FooterListItem show>
                                            {market === 'pl' ? (
                                                <ExternalLink
                                                    href={weblinks.presslink}
                                                    label={t('footer:press')}
                                                >
                                                    {t('footer:press')}
                                                </ExternalLink>
                                            ) : (
                                                <Link
                                                    query={
                                                        // Only do below on the affected page
                                                        // for cleaner url.
                                                        router.pathname ===
                                                        routes.press.href
                                                            ? ({
                                                                  // We need a dynamic query that
                                                                  // we can use for reloading the
                                                                  // iframe in /press if we click
                                                                  // press again we are on that page.
                                                                  reload: Date.now()
                                                              } as unknown as RouterQuery)
                                                            : undefined
                                                    }
                                                    route={routes.press}
                                                >
                                                    <Text
                                                        as="span"
                                                        t="footer:press"
                                                    />
                                                </Link>
                                            )}
                                        </FooterListItem>
                                    )}
                                    {weblinks?.jobs && (
                                        <FooterListItem show>
                                            <ExternalLink
                                                href={weblinks.jobs}
                                                label={t('footer:work')}
                                            >
                                                {t('footer:work')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    <FooterListItem>
                                        <Link
                                            route={routes.environmentalImpact}
                                        >
                                            <Text
                                                as="span"
                                                t="footer:environmentalImpact"
                                            />
                                        </Link>
                                    </FooterListItem>
                                </List>
                                <List className={cssFooter.navList}>
                                    <ListItem>
                                        <Text
                                            t="footer:followHeader"
                                            color="primary-white"
                                        />
                                    </ListItem>
                                    {weblinks?.instagram && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.instagram}
                                                label={t('footer:instagram')}
                                            >
                                                {t('footer:instagram')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.facebook && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.facebook}
                                                label={t('footer:facebook')}
                                            >
                                                {t('footer:facebook')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.tiktok && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.tiktok}
                                                label={t('footer:tiktok')}
                                            >
                                                {t('footer:tiktok')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.linkedin && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.linkedin}
                                                label={t('footer:linkedIn')}
                                            >
                                                {t('footer:linkedIn')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                </List>

                                <List className={cssFooter.navList}>
                                    <ListItem>
                                        <Text
                                            t="footer:contactHeader"
                                            color="primary-white"
                                        />
                                    </ListItem>
                                    {weblinks?.support && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.support}
                                                label={t('footer:faq')}
                                            >
                                                {t('footer:faq')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {showImpressum ? (
                                        <Impressum />
                                    ) : (
                                        <FooterListItem>
                                            <Link
                                                route={{
                                                    href: routes.contact.href,
                                                    name: t('footer:contact')
                                                }}
                                            >
                                                {t('footer:contact')}
                                            </Link>
                                        </FooterListItem>
                                    )}
                                    <FooterListItem
                                        show={additionalFooterItems?.includes(
                                            'school'
                                        )}
                                    >
                                        <Link
                                            route={{
                                                href: routes.school.href,
                                                name: t('footer:school')
                                            }}
                                        >
                                            {t('footer:school')}
                                        </Link>
                                    </FooterListItem>
                                </List>
                                <List className={cssFooter.navList}>
                                    <ListItem>
                                        <Text
                                            t="footer:informationHeader"
                                            color="primary-white"
                                        />
                                    </ListItem>
                                    {weblinks?.termsandconditions && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={
                                                    weblinks?.termsandconditions
                                                }
                                                label={t('footer:terms')}
                                            >
                                                {t('footer:terms')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.privacypolicy && (
                                        <FooterListItem>
                                            <ExternalLink
                                                href={weblinks.privacypolicy}
                                                label={t(
                                                    'footer:privacyNotice'
                                                )}
                                            >
                                                {t('footer:privacyNotice')}
                                            </ExternalLink>
                                        </FooterListItem>
                                    )}
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.cookies.href,
                                                name: t('footer:cookies')
                                            }}
                                        >
                                            {t('footer:cookies')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <CookieSettingsButton />
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.sitemap.href,
                                                name: t('footer:sitemap')
                                            }}
                                        >
                                            {t('footer:sitemap')}
                                        </Link>
                                    </FooterListItem>

                                    <FooterListItem
                                        show={Boolean(showPartners)}
                                    >
                                        <Link
                                            route={{
                                                href: routes.partners.href,
                                                name: t('footer:partners')
                                            }}
                                        >
                                            {t('footer:partners')}
                                        </Link>
                                    </FooterListItem>

                                    <FooterListItem
                                        show={enabledCancelSubscription}
                                    >
                                        <Link
                                            route={{
                                                href: routes.cancelSubscription
                                                    .href,
                                                name: t(
                                                    'footer:cancelSubscription'
                                                )
                                            }}
                                        >
                                            {t('footer:cancelSubscription')}
                                        </Link>
                                    </FooterListItem>
                                </List>

                                <List className={cssFooter.navList}>
                                    <ListItem>
                                        <Text
                                            t="footer:bookBeat"
                                            color="primary-white"
                                        />
                                    </ListItem>
                                    <FooterListItem>
                                        <Link route={routes.userDetails}>
                                            {t('footer:tryBookBeat')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.books.href,
                                                name: t('nav:books')
                                            }}
                                        >
                                            {t('nav:books')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes['e-books'].href,
                                                name: t('footer:eBooksFooter')
                                            }}
                                        >
                                            {t('footer:eBooksFooter')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.categories.href,
                                                name: t('footer:categories')
                                            }}
                                        >
                                            {t('footer:categories')}
                                        </Link>
                                    </FooterListItem>
                                    <FooterListItem>
                                        <Link
                                            route={{
                                                href: routes.search.href,
                                                name: t('footer:search')
                                            }}
                                        >
                                            {t('footer:search')}
                                        </Link>
                                    </FooterListItem>
                                    {showGiftCard && (
                                        <FooterListItem>
                                            <Link
                                                route={{
                                                    href: routes.buyGiftcards
                                                        .href,
                                                    name: t('footer:giftCard')
                                                }}
                                            >
                                                {t('footer:giftCard')}
                                            </Link>
                                        </FooterListItem>
                                    )}
                                    {weblinks?.familyaccount && (
                                        <FooterListItem>
                                            <Link
                                                route={{
                                                    href: routes.familyaccount
                                                        .href,
                                                    name: t(
                                                        'footer:familyAccount'
                                                    )
                                                }}
                                            >
                                                {t('footer:familyAccount')}
                                            </Link>
                                        </FooterListItem>
                                    )}
                                </List>

                                <List
                                    alignItems="flexStart"
                                    className={cssFooter.navList}
                                >
                                    <ListItem>
                                        <Text
                                            t="footer:download"
                                            color="primary-white"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <AppDownloadBadges
                                            appStoreLink={t(
                                                'common:appStoreLink'
                                            )}
                                            color="white"
                                            className={commonCss.noPadding}
                                        />
                                        <AppDownloadBadges
                                            playStoreLink={t(
                                                'common:playStoreLink'
                                            )}
                                            color="white"
                                            className={commonCss.noPadding}
                                        />
                                    </ListItem>
                                </List>
                            </Row>
                        </Stack>
                    </MaxWidthContainer>
                    <MaxWidthContainer maxWidth={992}>
                        <Box marginTop="medium">
                            <Flex wrap="wrapReverse" alignItems="center">
                                <Box className={cssFooter.copyrightColumn}>
                                    <Text color="primary-white">
                                        &copy;BookBeat AB,{' '}
                                        {new Date().getFullYear()}
                                    </Text>
                                </Box>
                                <Box className={cssFooter.columnContainer}>
                                    <Flex
                                        alignItems="center"
                                        className={cssFooter.marketColumn}
                                        gap="small"
                                    >
                                        <MarketPickerButton />
                                    </Flex>
                                    <Flex
                                        alignItems="center"
                                        className={cssFooter.paymentColumn}
                                    >
                                        {paymentMethods?.map((method) => (
                                            <Fragment key={method.type}>
                                                {
                                                    paymentConfig[
                                                        method.type as keyof typeof paymentConfig
                                                    ]
                                                }
                                            </Fragment>
                                        ))}
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    </MaxWidthContainer>
                </PagePaddingBox>
            </nav>
        </footer>
    );
};
